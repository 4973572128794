<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div
            class="d-flex justify-space-between flex-wrap align-baseline full-width"
          >
            <div>Events</div>
            <div class="event-filters d-flex justify-space-between">
              <v-select
                class="filter-input"
                v-model="user"
                :items="users"
                item-text="label"
                item-value="value"
                label="Admin Users"
                outlined
                dense
              ></v-select>

              <v-autocomplete
                class="filter-input"
                v-model="student"
                :items="students"
                item-text="label"
                item-value="value"
                label="Students"
                autocomplete="off"
                outlined
                dense
              >
                <template v-slot:append-item>
                  <div v-intersect="onStudentsIntersect"></div>
                </template>
              </v-autocomplete>

              <v-autocomplete
                class="filter-input"
                v-model="customer"
                :items="customers"
                item-text="label"
                item-value="value"
                label="Customers"
                autocomplete="off"
                outlined
                dense
              >
                <template v-slot:append-item>
                  <div v-intersect="onCustomersIntersect"></div>
                </template>
              </v-autocomplete>

              <v-autocomplete
                class="ml-2"
                v-model="courseLookup"
                :items="courseLookups"
                item-text="label"
                item-value="value"
                label="Courses"
                autocomplete="off"
                outlined
                dense
              >
                <template v-slot:append-item>
                  <div v-intersect="onCourseLookupsIntersect"></div>
                </template>
              </v-autocomplete>
            </div>
          </div>

          <div class="event-filters d-flex align-baseline ml-auto mt-3">
            <v-text-field
              v-model="search"
              class="mx-2"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-select
              v-model="type"
              class="filter-input"
              :items="types"
              label="Type"
              clearable
              outlined
              dense
            ></v-select>
            <v-dialog ref="dialog" v-model="dialog" width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="filter-input"
                  v-model="dateRangeText"
                  label="Date range"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dates = []"
                  clearable
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-btn class="ml-2" dark color="primary" @click="paginate">
              Filter
            </v-btn>
          </div>
        </v-card-title>
        <v-data-table
          item-key="id"
          class="elevation-1 table-one"
          :headers="headers"
          :items="getEvents.length ? getEvents : []"
          :page="getEventsPage"
          :pageCount="getEventsPagesNumber"
          :items-per-page="10"
          :options.sync="options"
          :server-items-length="getEventsTotal"
          :loading="getEventsLoading"
          :disable-sort="true"
          :disable-filtering="true"
        >
          <template v-slot:item.created_at="{ item }">
            <div class="description">
              {{ item.created_at | datetime }}
            </div>
          </template>
          <template v-slot:item.code="{ item }">
            {{ item.code }}
          </template>
          <template v-slot:item.user="{ item }">
            {{ item | userName }}
          </template>
          <template v-slot:item.description="{ item }">
            <div>{{ item.description | parse }}</div>
          </template>
          <template v-slot:item.user_agent="{ item }">
            <div>{{ item.user_agent }}</div>
          </template>
          <template v-slot:item.window_dimensions="{ item }">
            <div>{{ item.window_dimensions }}</div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { getCourseLookupFilter } from "@/services/courseLookup";
import { getCustomersFilter } from "@/services/customerService";
import { getTypes } from "@/services/eventService";
import { getStudentsFilter } from "@/services/studentService";
import { getAllUsers } from "@/services/userService";
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Event",
  },
  data() {
    return {
      model: "event",
      page: null,
      dates: [],
      types: [],
      users: [],
      students: [],
      customers: [],
      courseLookups: [],
      type: null,
      user: null,
      student: null,
      customer: null,
      courseLookup: null,
      selected: null,
      shouldRemoveModel: false,
      dialog: false,
      search: "",
      options: {},
      headers: [
        { text: "Created", value: "created_at" },
        { text: "Event Type", value: "code" },
        { text: "User", value: "user" },
        { text: "Description", value: "description" },
        { text: "User Agent", value: "user_agent", width: "25%" },
        { text: "Dimensions", value: "window_dimensions", width: "5%" },
      ],
      studentsPerPage: 10,
      studentsPage: 1,
      studentsTotal: 0,
      customersPerPage: 10,
      customersPage: 1,
      customersTotal: 0,
      courseLookupsPerPage: 10,
      courseLookupsPage: 1,
      courseLookupsTotal: 0,
    };
  },
  mounted() {
    this.page = this.$store.getters.getPage({ model: this.model });
    this.getTypes();
    this.getUsers();
    this.getStudents();
    this.getCustomers();
    this.getCourseLookup();
  },
  methods: {
    ...mapActions(["setEvents"]),
    async getTypes() {
      try {
        const { data } = await getTypes();
        this.types = _.map(data, (item) => item.code);
      } catch (error) {
        console.error(error);
      }
    },
    async getUsers() {
      try {
        const selections = [];
        selections.push({
          label: "All Admin Users",
          value: null,
        });

        const { data } = await getAllUsers();
        _.map(data, (item) => {
          selections.push({
            label: item.name,
            value: item.id,
          });
        });

        this.users = selections;
      } catch (error) {
        console.error(error);
      }
    },
    async getStudents() {
      try {
        const selections = [];
        selections.push({
          label: "All Students",
          value: null,
        });

        const { data } = await getStudentsFilter({
          perPage: this.studentsPerPage,
          page: this.studentsPage,
        });

        this.studentsTotal = _.get(data, "total", 0);

        _.map(_.get(data, "data"), (item) => {
          selections.push({
            label: item.name,
            value: item.id,
          });
        });

        this.students = selections;
      } catch (error) {
        console.error(error);
      }
    },
    async getCustomers() {
      try {
        const selections = [];
        selections.push({
          label: "All Customers",
          value: null,
        });

        const { data } = await getCustomersFilter({
          perPage: this.customersPerPage,
          page: this.customersPage,
        });

        this.customersTotal = _.get(data, "total", 0);

        _.map(_.get(data, "data"), (item) => {
          selections.push({
            label: item.description,
            value: item.id,
          });
        });

        this.customers = selections;
      } catch (error) {
        console.error(error);
      }
    },
    async getCourseLookup() {
      try {
        const selections = [];
        selections.push({
          label: "All Courses",
          value: null,
        });

        const { data } = await getCourseLookupFilter({
          perPage: this.courseLookupsPerPage,
          page: this.courseLookupsPage,
        });

        this.courseLookupsTotal = _.get(data, "total", 0);

        _.map(_.get(data, "data"), (item) => {
          selections.push({
            label: item.description,
            value: item.id,
          });
        });

        this.courseLookups = selections;
      } catch (error) {
        console.error(error);
      }
    },
    paginate() {
      const { page, itemsPerPage } = this.options;
      this.setEvents({
        page,
        perPage: itemsPerPage,
        type: this.type,
        dates: this.sortedDates,
        user: this.user,
        student: this.student,
        customer: this.customer,
        courseLookup: this.courseLookup,
        search: this.search,
      });
    },
    async onStudentsIntersect() {
      const total = this.studentsTotal + 1;

      if (_.size(this.students) >= total) {
        return;
      }

      this.studentsPage += 1;

      const selections = [];

      const { data } = await getStudentsFilter({
        perPage: this.studentsPerPage,
        page: this.studentsPage,
      });

      _.map(_.get(data, "data"), (item) => {
        selections.push({
          label: item.name,
          value: item.id,
        });
      });

      this.students = [...this.students, ...selections];
    },

    async onCustomersIntersect() {
      const total = this.customersTotal + 1;

      if (_.size(this.customers) >= total) {
        return;
      }

      this.customersPage += 1;

      const selections = [];

      const { data } = await getCustomersFilter({
        perPage: this.customersPerPage,
        page: this.customersPage,
      });

      _.map(_.get(data, "data"), (item) => {
        selections.push({
          label: item.description,
          value: item.id,
        });
      });

      this.customers = [...this.customers, ...selections];
    },

    async onCourseLookupsIntersect() {
      const total = this.courseLookupsTotal + 1;

      if (_.size(this.courseLookups) >= total) {
        return;
      }

      this.courseLookupsPage += 1;

      const selections = [];

      const { data } = await getCourseLookupFilter({
        perPage: this.courseLookupsPerPage,
        page: this.courseLookupsPage,
      });

      _.map(_.get(data, "data"), (item) => {
        selections.push({
          label: item.description,
          value: item.id,
        });
      });

      this.courseLookups = [...this.courseLookups, ...selections];
    },
  },
  computed: {
    ...mapGetters([
      "getEvents",
      "getEventsPage",
      "getEventsTotal",
      "getEventsPagesNumber",
      "getEventsLoading",
    ]),
    dateRangeText: {
      get() {
        return this.dates ? this.dates.join(" ~ ") : "";
      },
      set(value) {
        return value;
      },
    },
    sortedDates() {
      const dates =
        _.size(this.dates) === 1
          ? [...this.dates, _.get(this.dates, "0")]
          : this.dates;

      return dates.slice().sort((a, b) => new Date(a) - new Date(b));
    },
  },
  watch: {
    options: {
      handler() {
        this.paginate();
      },
    },
    deep: true,
  },
  filters: {
    parse(value) {
      try {
        const data = JSON.parse(value);
        return Object.keys(data)
          .map((key) => `${key}: ${data[key]}`)
          .join(", ");
      } catch (error) {
        return value;
      }
    },
    userName(value) {
      if (value.user_id) {
        return `ADMIN: ${_.get(value, "user.name", "—")}`;
      }

      if (value.student_id) {
        return `STUDENT: ${_.get(value, "student.name", "—")}`;
      }

      if (value.customer_id) {
        return `CUSTOMER: ${_.get(value, "customer.description", "—")}`;
      }

      return "—";
    },

    datetime(value) {
      return moment(value).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>
<style lang="scss" scoped>
.full-width {
  width: 100%;
}
.event-filters {
  .filter-input {
    margin: 0 0.5rem;
    width: 12em;
  }

  ::v-deep .v-text-field__details {
    display: none;
  }
}

::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }

      .description {
        min-width: 120px;
      }

      td {
        &:nth-child(3) {
          word-break: break-word;
          word-wrap: break-word;

          div {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
